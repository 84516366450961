import React from 'react'
import { Link, graphql } from 'gatsby'

const BlogPostTemplate = ({ data, pageContext, location }) => {
    const post = data.markdownRemark
    const { previous, next } = pageContext

    return (
        <div className='mt-6'>
            <header className='max-w-4xl mx-auto border-b border-gray-200 mb-2 sm:flex items-center justify-between px-2'>
                <h1 className='font-semibold text-xl md:text-4xl'>{post.frontmatter.title}</h1>
                <span>{post.frontmatter.date}</span>
            </header>
            <article
                className='px-2 mt-12 mb-2 prose prose-sm sm:prose lg:prose-lg xl:prose-xl mx-auto'
                dangerouslySetInnerHTML={{ __html: post.html }}
            />

            <nav className='mt-2'>
                <ul
                    style={{
                        display: `flex`,
                        flexWrap: `wrap`,
                        justifyContent: `space-between`,
                        listStyle: `none`,
                        padding: 0,
                    }}
                >
                    <li>
                        {previous && (
                            <Link to={previous.fields.slug} rel='prev'>
                                ← Previous: {previous.frontmatter.title}
                            </Link>
                        )}
                    </li>
                    <li>
                        {next && (
                            <Link to={next.fields.slug} rel='next'>
                                Next: {next.frontmatter.title} →
                            </Link>
                        )}
                    </li>
                </ul>
            </nav>
        </div>
    )
}

export default BlogPostTemplate

export const pageQuery = graphql`
    query BlogPostBySlug($slug: String!) {
        site {
            siteMetadata {
                title
            }
        }
        markdownRemark(fields: { slug: { eq: $slug } }) {
            id
            excerpt(pruneLength: 160)
            html
            frontmatter {
                title
                date(formatString: "MMMM DD, YYYY")
                description
            }
        }
    }
`
